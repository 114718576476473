<template>
  <div class="webWrap">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
    <div class="sidebar">
      <ul class="ul">
        <li @mouseover="mouseover(1)" @mouseleave="mouseleave(1)">
          <img src="static/lvya/011.png" alt="" v-if="tc1">
          <img src="static/lvya/01.png" alt="" v-else>
          <div class="left-tc" v-if="tc1">
            <div class="ewm_div">
              <div class="div">
                <img src="static/lvya/footer/ewm1.png" alt="">
                <p>金岭教育公众号</p>
              </div>
              <div class="div">
                <img src="static/lvya/footer/ewm2.png" alt="">
                <p>金岭教育视频号</p>
              </div>
            </div>
          </div>
        </li>
        <li @mouseover="mouseover(2)" @mouseleave="mouseleave(2)">
          <img src="static/lvya/022.png" alt="" v-if="tc2">
          <img src="static/lvya/02.png" alt="" v-else>
          <div class="left-tc tc2" v-if="tc2">
            <p class="p1"><img src="static/lvya/phone.png" alt=""><span>欢迎拨打电话咨询</span></p>
            <p>010-64921605</p>
          </div>
        </li>
        <li @mouseover="mouseover(3)" @mouseleave="mouseleave(3)">
          <img src="static/lvya/033.png" alt="" v-if="tc3">
          <img src="static/lvya/03.png" alt="" v-else>
          <div class="left-tc tc3" v-if="tc3">
            <img src="static/lvya/schoolFzpt/index/yyys3.png" alt="">
            <p>微信扫码 在线咨询</p>
          </div>
        </li>
        <li @mouseover="mouseover(4)" @mouseleave="mouseleave(4)">
          <img src="static/lvya/044.png" alt="" v-if="tc4">
          <img src="static/lvya/04.png" alt="" v-else>
          <div class="left-tc tc4" v-if="tc4">
            <p class="p1"><img src="static/lvya/email.png" alt=""><span>欢迎邮件咨询</span></p>
            <p>lvya@lvya.org</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Header from './coms/Header.vue'
import Footer from './coms/Footer.vue'
export default {
  components: { Header, Footer },
  data() {
    return {
      tc1: false,
      tc2: false,
      tc3: false,
      tc4: false,
    }
  },
  methods: {
    mouseover(tc) {
      if (tc == '1') {
        this.tc1 = true
      } else if (tc == '2') {
        this.tc2 = true
      } else if (tc == '3') {
        this.tc3 = true
      } else if (tc == '4') {
        this.tc4 = true
      }
    },
    mouseleave(tc) {
      if (tc == '1') {
        this.tc1 = false
      } else if (tc == '2') {
        this.tc2 = false
      } else if (tc == '3') {
        this.tc3 = false
      } else if (tc == '4') {
        this.tc4 = false
      }
    },
  },
  created() {},
  mounted() {},
}
</script>
<style lang="less">
@import url('./style/base.less');
@import url('./style/animate.css');
.webWrap{
  .sidebar{
    width: 60px;
    min-height: 100px;
    background: #fff;
    position: fixed;
    right: 0;
    top: 60%;
    box-shadow:2px 2px 10px rgb(224, 224, 224);
    border-radius: 30px;
    z-index: 99999;
    .ul{
      background: #fff;
      padding: 0px 13px;
      box-sizing: border-box;
      border-radius: 30px;
      // box-shadow:2px 2px 10px rgb(224, 224, 224);
      li{
        box-sizing: border-box;
        text-align: center;
        position: relative;
        border-bottom: 1px solid #d9e0ea;
        cursor: pointer;
        padding: 5px 0;
        p{
          font-size: 14px;
          color: #222;
          margin: 0 auto;
        }
        img{
          max-width: 100%;
        }
        .left-tc{
          min-width: 100px;
          min-height: 80px;
          position: absolute;
          left: -380px;
          top: -32px;
          background: #fff;
          padding: 20px;
          box-sizing: border-box;
          box-shadow:2px 2px 50px rgb(224, 224, 224);
          text-align: center;
          img{
            max-width: 155px;
            max-height: 155px;
          }
          .ewm_div{
            display: flex;
          }
        }
        .left-tc:before{
          content:"";
          display: block;
          border-color: transparent;
          border-left-color: #fff;
          border-width: 10px;
          border-style:solid;
          width:0;
          height:0;
          position:absolute;
          top:20%;
          left:100%;
        }
        .tc2,.tc4{
          left: -225px;
          top: -14px;
          .p1{
            font-size: 16px;
            color: #333;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            span{
              display: inline-block;
              margin-left: 4px;
            }
          }
          p{
            margin-top: 5px;
          }
        }
        .tc4{
          left: -195px;
        }
        .tc3{
          left: -230px;
        }
      }
      li:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
